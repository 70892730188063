<template>
  <v-row v-if="completed" class="final" style="overflow: hidden">
    <v-img height="900px" src="@/assets/background.jpg"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="10" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col cols="11" md="4" class="pa-0" src="@/assets/background.jpg">
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("inscriptions.tattooer_complete_box") }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-btn
          style="height: 30px"
          outlined
          @click="$router.push({ name: 'boxList' })"
          >{{ $tc("inscriptions.go_boxes", 2) }}</v-btn
        >
      </v-row>
    </v-img>
  </v-row>
  <v-row v-else-if="already_done" class="final" style="overflow: hidden">
    <v-img height="900px" src="@/assets/background.jpg"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="10" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col cols="12" md="4" class="pa-0" src="@/assets/background.jpg">
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("inscriptions.tattooer_already_complete") }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-btn
          style="height: 30px"
          outlined
          @click="$router.push({ name: 'Login' })"
          >{{ $tc("inscriptions.go_log_in", 2) }}</v-btn
        >
      </v-row>
    </v-img>
  </v-row>
  <studio-card id="invite-tattooer" v-else>
    <v-stepper v-model="e1" alt-labels>
      <v-stepper-header>
        <v-spacer></v-spacer>
        <v-stepper-step
          step="1"
          v-if="!$store.getters['auth/isTattooer']"
          :complete="e1 > 1"
          ><div
            style="
              text-align: center;
              color: var(--v-primary-base);
              text-transform: uppercase;
            "
          >
            {{ $t("tattooers.invites.personal_info") }}
          </div></v-stepper-step
        >

        <!--<v-stepper-step step="2" :complete="e1 > 2"
          ><div
            style="
              text-align: center;
              color: var(--v-primary-base);
              text-transform: uppercase;
            "
          >
            {{ $t("tattooers.invites.stance_info") }}
          </div></v-stepper-step
        >-->
        <v-divider v-if="!$store.getters['auth/isTattooer']"></v-divider>
        <v-stepper-step
          :step="!$store.getters['auth/isTattooer'] ? '2' : '1'"
          :complete="!$store.getters['auth/isTattooer'] ? e1 > 1 : e1 > 2"
        >
          <div
            style="
              text-align: center;
              color: var(--v-primary-base);
              text-transform: uppercase;
            "
          >
            {{ $t("tattooers.invites.info_box") }} {{ studio.studio_name }}
          </div>
        </v-stepper-step>
        <v-divider v-if="havePayments"></v-divider>

        <v-stepper-step
          v-if="havePayments"
          :step="!$store.getters['auth/isTattooer'] ? '3' : '2'"
          :complete="!$store.getters['auth/isTattooer'] ? e1 > 2 : e1 > 3"
        >
          <div
            style="
              text-align: center;
              color: var(--v-primary-base);
              text-transform: uppercase;
            "
          >
            {{ $t("tattooers.invites.payment") }}
          </div>
        </v-stepper-step>
        <!--
          <v-stepper-step step="3" :complete="e1 > 3">
            <div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("tattooers.invites.share_info") }}
            </div>
          </v-stepper-step>
        -->
        <v-spacer></v-spacer>
        <v-row
          v-if="$vuetify.breakpoint.smAndDown"
          style="display: block; width: 100%; margin-top: -30px"
          justify="center"
          ><v-col>
            <div
              v-if="e1 === 1"
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("tattooers.invites.personal_info") }}
            </div>
            <!--<div
              v-else-if="e1 === 2"
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("tattooers.invites.stance_info") }}
            </div>-->
            <div
              v-else
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("tattooers.invites.payment") }}
            </div>
          </v-col>
        </v-row>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content v-if="!$store.getters['auth/isTattooer']" step="1">
          <tattooers-form
            :title="$t('new', { name: $tc('tattooer') })"
            :tattooer="tattooer"
            @validated="
              e1++;
              $refs.infoBox.fetchTable();
            "
          ></tattooers-form>
        </v-stepper-content>

        <v-stepper-content
          :step="!$store.getters['auth/isTattooer'] ? '2' : '1'"
        >
          <InformationBox
            ref="infoBox"
            :res="res"
            @validated="validate"
            @back="e1--"
          ></InformationBox>
        </v-stepper-content>
        <v-stepper-content
          :step="!$store.getters['auth/isTattooer'] ? '3' : '2'"
        >
          <payment ref="payment" />
        </v-stepper-content>
        <!--<v-stepper-content step="3">
         <information-share
            :tattooerStudio="tattooer.tattooerStudio"
            @validated="validate"
            @back="e1--"
          ></information-share>
        </v-stepper-content>-->
      </v-stepper-items>
    </v-stepper>
  </studio-card>
</template>

<script>
import constants from "@/constants";
import { mapActions, mapState } from "vuex";
export default {
  name: "TattooerReservationBox",
  components: {
    TattooersForm: () => import("@/components/tattooer/Form"),
    StudioInscription: () =>
      import("@/components/inscription/tattooer/StudioInscription"),
    InformationShare: () =>
      import("@/components/inscription/tattooer/InformationShare"),
    InformationBox: () =>
      import("@/components/inscription/tattooer/InformationBox"),
    StudioCard: () => import("@/components/inscription/studio/Card"),
    payment: () => import("@/components/inscription/customer/Payment"),
  },
  mounted() {
    this.tattooer = {
      tattooerStudio: {
        timetable: [[], [], [], [], [], [], []],
      },
      token_payment: [],
      user: {
        role_id: constants.roles.TATTOOER,
      },
      social_networks: {},
    };
    this.fetchStudio();

    this.res.studio = this.studio.id;

    //console.log(this.res);
  },
  computed: {
    ...mapState("inscriptions", ["studioState", "tattooerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },

    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    havePayments() {
      if (this.studio.user) {
        let s = this.studio.user.setting.find((x) => {
          return x.key == "box_price";
        });
        if (s) return s.value === "1";
      }
      return false;
    },
  },
  methods: {
    ...mapActions("studios", ["getStudioByToken"]),
    fetchStudio() {
      this.getStudioByToken(this.$route.params.token).then((result) => {
        this.studio = result;
        //console.log(this.studio);
      });
    },

    ...mapActions("inscriptions", ["newTattooerBox"]),

    validate() {
      if (this.$route.name === "new_reservation_box") {
        //console.log("validated new");
        //console.log(this.res);

        this.tattooer.tattooerStudio.from = this.res.date;
        this.tattooer.reservation = this.res;
        this.tattooer.tattooerStudio.studio_id = this.studio.id;

        this.newTattooerBox({
          tattooer: this.tattooer,
        }).then((response) => {
          if (response.success == true && response.reserva == false) {
            //console.log(response.reserva);
            this.response(response);
          } else {
            this.$alert("No hay disponibilidad para esta fecha", "", "");
          }
        });
      }
    },

    response(response) {
      if (!this.havePayments) {
        this.completed = true;
      } else {
        this.e1++;
        this.$refs.payment.load(response.box);
      }
    },

    login() {
      this.$router.push({ name: "Login", params: { inscription: true } });
    },
  },
  data() {
    return {
      res: {
        id: null,
        date: null,
        start: null,
        end: null,

        associate: false,
      },

      e1: 1,
      completed: false,
      already_done: false,
    };
  },
};
</script>
<style lang="sass">
#invite-tattooer
  label
    display: block
    height: 25px
#inscription
  .v-responsive__sizer
    display: none !important
</style>